import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {derived_properties: {waxed: { eq: "vaxade" }}}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				color
				elastic
				form
				waxed
				the_length
			}
			
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "vaxade-skosnören"
    }}>{`Vaxade Skosnören`}</h1>
    <p>{`Vaxade skosnören används ofta till lite finare skor, men inte uteslutande.
En fördel med att skosnörena är vaxade är att knutar inte går upp lika lätt. Man bör även överväga vaxade skosnören
till en kostym eller finare klädsel då skorna ofta kräver lite finare
skosnören som är blanka. När det gäller finskor, så är grundregeln att du ska ha svarta skosnören till svarta skor, och bruna skosnören till bruna skor. Håller du dina finskor i fint skick med regelbunden putsning, glöm då inte att även uppgradera skosnören då och då så att inte de ser slitna ut i förhållande till dina skor.`}</p>
    <p>{`En annan fördel med vaxade skosnören är att de inte är lika töjbara som skosnören som inte är vaxade. Detta kan vara bra om man vill ha en knut som sitter riktigt hårt och inte blir lös över tid, t.ex. om man skaknyta ett par skridskor eller vanrdingskängor hårt. Anledningen till att vaxade skosnören inte töjer sig är att de töjs ut innan man applicerar vaxet, sedan härdar vaxet och skosnörena förblir i det uttöjda läget och töjs inte mer.`}</p>
    <p>{`Här hittar du alla vaxade skosnören, oavsett om du är ute efter ett par runda
tunna till dina finskor, eller ett par platta för att ge mer premium-känsla
till dina sneakers.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>
    <h2 {...{
      "id": "vad-är-vaxade-skosnören"
    }}>{`Vad är vaxade skosnören?`}</h2>
    <p>{`Vaxade skosnören är helt enkelt ett par skosnören (syntetiska eller i bomull)
som har blivit behandlat med ett lager av vax på ytan. Skosnörena töjs ut, vax appliceras, och sedan härdar vaxet.`}</p>
    <h2 {...{
      "id": "varför-vaxade-skosnören"
    }}>{`Varför vaxade skosnören?`}</h2>
    <p>{`När man vaxar skosnören så får man en hel del positiva egenskaper. Nedan
listar vi de stora fördelarna med vaxade skosnören:`}</p>
    <ul>
      <li parentName="ul">{`De blir vattenavvisande`}</li>
      <li parentName="ul">{`Knutar går inte upp lika lätt`}</li>
      <li parentName="ul">{`Ger mer "premium"-känsla`}</li>
      <li parentName="ul">{`De blir mindre sladdriga`}</li>
      <li parentName="ul">{`De håller formen bättre och töjs inte. Hårda knutar förblir därför hårda`}</li>
    </ul>
    <p>{`Det finns dock även nackdelar, som vi listar nedan:`}</p>
    <ul>
      <li parentName="ul">{`Svåra att knyta upp`}</li>
      <li parentName="ul">{`Vaxet kan lossna efter en viss tids av användning`}</li>
      <li parentName="ul">{`Hårdare och kan skava lite mer när man knyter hårda knutar`}</li>
    </ul>
    <h2 {...{
      "id": "runda-vaxade-skosnören"
    }}>{`Runda vaxade skosnören`}</h2>
    <p>{`Den vanligaste formen på vaxade skosnören är runda. Framförallt tunna runda
vaxade skosnören som nästan uteslutande är det enda valet till herr-finskor.`}</p>
    <h2 {...{
      "id": "platta-vaxade-skosnören"
    }}>{`Platta vaxade skosnören`}</h2>
    <p>{`Historiskt sett så har endast runda skosnören varit vaxade. Men på senare år
har det även dykt upp mer och mer vaxade platta skosnören. Man brukar se dessa
hos två typer av skor. Den ena typen är "stadskängor" där man inte har samma
krav på slitstyrka som hos vandringskängor samtidigt som man vill ha en lite
mer robustare stil. Ofta är det dyrare kängor i läder där man även kostar på
sig ett par vaxade runda skosnören.`}</p>
    <p>{`Den andra typen är sneakers. Och då kanske inte de vanliga slit och släng
snekears utan snarare de lite dyrare designade sneakersskorna där ett par
vaxade skosnören ökar premium-känslan ytterligare. Dessutom får man andra
egenskaper på köpet, till exempel vattenavvisande egenskaper samt att knutarna
håller lite bättre då knutar i regel håller ganska dåligt hos platta
skosnören.`}</p>
    <h2 {...{
      "id": "inte-hittat-rätt"
    }}>{`Inte hittat rätt?`}</h2>
    <p>{`Nu har du förhoppningsvis hittat ett par vaxade skosnören som du känner
passar dig, för nu har vi inget mer att visa. Har du mot förmodan inte det
så kan du ta dig till en fysisk skobutik och kolla in deras utbud. Men i
regel hittar du flerfalt mycket fler vaxade skosnören online jämfört med i
butik.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      